<template>
  <div class="wrap">
    <div class="input_wrap">
      <el-input placeholder="请输入题目ID"
                style="width: 400rem;"
                v-model="keywords">
        <i slot="suffix"
           @click="searchTopic"
           class="el-input__icon el-icon-search"
           style="font-size:20rem;pointer:cursor"></i>
      </el-input>
      <div class="goal_text">为理想而战<img style=" line-height: 42px;"
             src="@/assets/medal.png"
             alt="">
      </div>
      <button class="btn_goal"
              @click="toGoal">
        <div class="text" v-if="totalScore !== 0"> 目标总分 {{ totalScore }} ></div>
        <div class="text" v-else> 快去填写高考总分 </div>
      </button>
    </div>
    <div class="index_warp">
      <div class="left">
        <Top ref="leftRef" />
      </div>
      <div class="right">
        <Bottom ref="rightRef" />
      </div>
    </div>
    <div class="autoModal"
         v-if="autoModalFlag">
      <div class="openButton">

        <el-button type="warning"
                   @click="toBuy"
                   class="btntext"><span class="text">开通VIP享受全部功能</span></el-button>
      </div>
    </div>
  </div>
</template>
<script>
import Top from './components/top.vue'
import Bottom from './components/bottom.vue'
import { getStudentSubject } from '@/api/subject.js'
import { getTopic } from '@/api/topic.js'
import Image from '../paper_components/correct/components/image.vue'
export default {
  data () {
    return {
      keywords: '',
      autoModalFlag: false,
      totalScore: 0,
    }
  },
  async mounted () {
    this.totalScore = JSON.parse(window.localStorage.getItem('totalScore'));
    this.$EventBus.on('totalScoreEdit', data => {
      this.totalScore = data
    })
    if (this.totalScore > 0) {
      window.localStorage.setItem('score_dialog', false)
    }
    let userInfo = JSON.parse(window.localStorage.getItem('userInfo'));

    if (userInfo.user_type_id == 14 && !userInfo.vip_end_time) {
      this.autoModalFlag = true
    }
  },
  methods: {
    toBuy () {
      this.$router.push('/buyVip')
    },
    toGoal () {
      this.$router.push('/studyGoal')
    },
    async searchTopic () {
      const { data } = await getTopic({
        question_id: this.keywords
      })
      if (data.list && data.list.length) {
        this.$router.push('showTopic?topic_id=' + this.keywords)
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    // let keep_alive_path = ['/group_test', '/myPaper', '/analysis', '/choiceness', '/knowledge', '/wholepaper_list', '/discuss', '/chapter']
    let keep_alive_path = ['/analysis', '/discuss', '/chapter']
    if (keep_alive_path.indexOf(to.path) != -1) {
      to.meta.keep = true;
    }
    next();
  },

  components: {
    Top, Bottom
  },

}
</script>

<style lang="scss" scoped>
.autoModal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1.5;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
  .btntext {
    position: fixed;
    left: 800rem;
    top: 700rem;
    .text {
      color: var(--light-333333, #333);
      text-align: center;

      font-size: 24rem;
      font-style: normal;
      font-weight: 600;
      line-height: 36rem; /* 150% */
    }
  }
  .openButton {
    ::v-deep .el-button {
      display: flex;
      width: 360rem;
      height: 64rem;
      justify-content: center;
      align-items: center;
      gap: 10rem;
      flex-shrink: 0;
      border-radius: 8rem;
      background: linear-gradient(100deg, #fdecc9 0%, #e9c383 100%);
      box-shadow: 0rem 8rem 16rem 0rem rgba(0, 0, 0, 0.1);
    }
  }
}
.index_warp {
  width: 100%;
  display: flex;
  // height: calc(98.5vh - 75rem);
  .left {
    display: flex;
    width: 59.9vw;
    margin-top: 1.5vh;
    height: 100%;
    margin-left: 3vw;
  }
  .right {
    flex-shrink: 0;
    width: 27.6vw;
    margin-top: 1.5vh;
    height: 100%;
    margin-left: 3vw;
  }
}
.wrap {
  width: 100%;
}
.input_wrap {
  // width: 400rem;
  // position: absolute;
  // top: 28rem;
  // right: 44rem;
  margin-top: 20rem;
  margin-left: 3vw;
  margin-right: 3vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  .goal_text {
    color: var(--light-333333, var(--neutral-color-333333, #333));
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 131.25% */
  }
  .btn_goal {
    display: flex;
    padding: var(--spacer-8_xs, 8px) var(--spacer-24_m, 24px);
    align-items: center;
    gap: var(--spacer-8_xs, 8px);
    border-radius: 100px;
    border: 1px solid #f37f21;
    background: #f37f21;
    .text {
      overflow: hidden;
      color: var(--light-ffffff, #fff);
      text-overflow: ellipsis;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px; /* 150% */
    }
  }
  ::v-deep .el-input--medium .el-input__inner {
    border-radius: 20rem;
    height: 44rem !important;
    line-height: 44rem;
    font-size: 18rem;
  }
  ::v-deep .el-input--medium .el-input__icon {
    margin-right: 20rem;
    line-height: 44rem;
    width: auto;
  }
}
</style>